import { THEMES } from "./const/themes.js";

let Calendar = {

    /**
     * Get a relevant theme for the given date
     *
     * @param {Date} - if not given current date is used
     *
     * @return {string} theme name
     */
    getTheme: function(d) {
        d = d || new Date();
        // try the holidays first
        if(d.getMonth() == 11) {
            return THEMES.CHRISTMAS;
        }
        if(d.getMonth() == 1 && d.getDate() <= 14) {
            return THEMES.VALENTINES;
        }
        if(d.getMonth() == 9) {
            return THEMES.HALLOWEEN;
        }
        let easterTimestamp = this.getEasterDate(d.getFullYear()).getTime();
        if(easterTimestamp > d.getTime() && easterTimestamp - d.getTime() <= 60*60*24*14*1000) {
            return THEMES.EASTER;
        }
        if(d.getMonth() < 2 || (d.getMonth() == 2 && d.getDate() < 20)) {
            return THEMES.WINTER;
        }
        if(d.getMonth() < 5 || (d.getMonth() == 5 && d.getDate() < 20)) {
            return THEMES.SPRING;
        }
        if(d.getMonth() < 8 || (d.getMonth() == 8 && d.getDate() < 22)) {
            return THEMES.SUMMER;
        }
        if(d.getMonth() < 11 || (d.getMonth() == 11 && d.getDate() < 22)) {
            return THEMES.FALL;
        }
        return THEMES.BASIC;
    },

    /**
     * Returns the date of Easter
     * see: https://www.irt.org/articles/js052/index.htm
     *
     * @param {int} year - if not givem current year is used
     *
     * @return {Date}
     */
    getEasterDate: function(Y) {
        Y = Y || (new Date()).getFullYear();
        var C = Math.floor(Y/100);
        var N = Y - 19*Math.floor(Y/19);
        var K = Math.floor((C - 17)/25);
        var I = C - Math.floor(C/4) - Math.floor((C - K)/3) + 19*N + 15;
        I = I - 30*Math.floor((I/30));
        I = I - Math.floor(I/28)*(1 - Math.floor(I/28)*Math.floor(29/(I + 1))*Math.floor((21 - N)/11));
        var J = Y + Math.floor(Y/4) + I + 2 - C + Math.floor(C/4);
        J = J - 7*Math.floor(J/7);
        var L = I - J;
        var M = 3 + Math.floor((L + 40)/44);
        var D = L + 28 - 31*Math.floor(M/4);
        return new Date(Y, M - 1, D, 12);
    }

};

export default Calendar;

import Shepherd from 'shepherd.js';
import {offset} from '@floating-ui/dom';

class TourGuide {

    constructor() {
        this.tour = new Shepherd.Tour({
            useModalOverlay: true,
            exitOnEsc: true,
            keyboardNavigation: true,
            defaultStepOptions: {
                //classes: 'shadow-md bg-purple-dark',
                scrollTo: true,
                scrollToHandler: function(el) {
                    if(el) {
                        if(el.scrollIntoViewIfNeeded) { el.scrollIntoViewIfNeeded(false); }
                        else { el.scrollIntoView({behavior: 'smooth'}); }
                    }
                },
                cancelIcon: {enabled: true},
                floatingUIOptions: {middleware: [offset(20)]},
                modalOverlayOpeningPadding: 10,
                modalOverlayOpeningRadius: 10,
                canClickTarget: false,
                buttons: [
                    {text: 'Back', classes: 'btn btn-secondary', action: function() { return this.back(); }},
                    {text: 'Next', classes: 'btn btn-primary', action: function() { return this.next(); }},
                ]
            }
        });
        this.tour.addSteps([
            {
                id: 'tour-1',
                title: 'Welcome to Bingo Card Creator',
                text: 'This short tour will guide you through using the Bingo Card Creator app.',
                buttons: [{text: 'Next', classes: 'btn btn-primary', action: function() { return this.next(); }}]
            },
            {
                id: 'tour-2',
                title: 'Designing Your Cards',
                text: 'Here is where you design and customize your bingo cards',
                scrollTo: false,
                attachTo: {element: '#controls', on: 'right-start'},
            },
            {
                id: 'tour-3',
                title: 'Previewing Your Cards',
                text: 'Here is where a sample of your current design is shown. This is how the cards will look when printed. It automatically updates to reflect your changes.',
                //scrollTo: false,
                attachTo: {element: '#preview', on: 'left-start'},
            },
            {
                id: 'tour-4',
                title: 'Theming Your Cards',
                text: 'You can select one of our themes to stylize your cards for various seasons or holidays.',
                attachTo: {element: '#card-theme-control-wrapper', on: 'right-start'},
            },
            {
                id: 'tour-5',
                title: 'Card Types',
                text: '<p>You can design two different types of cards.</p> <p><strong>Custom Content</strong> cards allow you to provide any text and/or images you want. You can also select the grid size from 3x3, 4x4 or 5x5 squares.</p><p><strong>75-Ball</strong> cards are 5x5 cards with numbers from 1-75</p>',
                attachTo: {element: '#card-type-control-wrapper', on: 'right-start'},
            },
            {
                id: 'tour-6',
                title: 'Custom Content',
                text: `<p>When designing a Custom Content card, this is the content to use for your bingo card squares.</p>
                       <p>You can edit the text in the input or upload images to display instead.</p>
                       <p>Click <strong>Add Item <svg class="bi" width="16" height="16" fill="currentColor"><use xlink:href="#sample-plus-square-icon"/></svg></strong> to additional items. Cards are randomly generated from the full list of items.</p><p> You should add more items than there are squares in your cards if the winner must cover every square (at least twice as many custom items as number of squares is recommended).</p>
                        <p>Click <svg class="bi" width="16" height="16" fill="currentColor"><use xlink:href="#sample-delete-icon"/></svg> to delete an item.</p>
                        <p>Click <svg class="bi" width="16" height="16" fill="currentColor"><use xlink:href="#sample-image-icon"/></svg> to add an image.</p>
                        <p>Click <svg class="bi" width="16" height="16" fill="currentColor"><use xlink:href="#sample-text-icon"/></svg> to revert an image item back to text.</p>`,
                scrollTo: false,
                attachTo: {element: '#card-custom-items-control-wrapper', on: 'right-start'},
                showOn: function() {
                    return !!document.querySelector("#card-custom-items-control-wrapper");
                }
            },
            {
                id: 'tour-7',
                title: 'Free Space',
                text: 'If this option is checked the center square of each card will be a "Free Space". Players immediately mark this square off at the start. You can customize the text you want to appear in this square.',
                attachTo: {element: '#card-freespace-control-wrapper', on: 'right-start'},
            },
            {
                id: 'tour-8',
                title: 'Font Size',
                text: 'Drag the slider to the right to increase the size of the text in the card squares, and drag it to left to decrease the text size.',
                attachTo: {element: '#font-size-control-wrapper', on: 'right-start'},
            },
            {
                id: 'tour-9',
                title: 'Saving Your Work',
                text: 'You can click the Save button at any time to save your card configuration to a file.',
                attachTo: {element: '#save-btn', on: 'right-start'},
            },
            {
                id: 'tour-10',
                title: 'Loading Your Saved Cards',
                text: '<p>Click the Load button to load a previously saved card.</p><p> Note your current configuration will be overwritten, so first save your work if you do not wish to lose your current card settings.</p>',
                attachTo: {element: '#load-btn', on: 'right-start'},
            },
            {
                id: 'tour-11',
                title: 'Printing Your Cards',
                text: '<p>Click the dropdown arrow on the Print button to configure how your cards will be printed.</p><p>You can select how many cards are printed on one page, as well as how many total cards to print</p>',
                attachTo: {element: '#print-btn-group .dropdown-menu', on: 'left-start'},
                canClickTarget: false,
                when: {
                    "before-show": function() {
                        let btnToggle = document.querySelector("#print-btn-group .dropdown-toggle");
                        if(!btnToggle.classList.contains('show')) {
                            window.requestAnimationFrame(() => { btnToggle.click(); });
                            //btnToggle.classList.add('show');
                            //btnToggle.parentNode.querySelector('.dropdown-menu').classList.add('show');
                        }
                    },
                    hide: function() {
                        let btnToggle = document.querySelector("#print-btn-group .dropdown-toggle");
                        if(btnToggle.classList.contains('show')) {
                            btnToggle.click();
                            //btnToggle.classList.remove('show');
                            //btnToggle.parentNode.querySelector('.dropdown-menu').classList.remove('show');
                        }
                    },
                }
            },
            {
                id: 'tour-12',
                title: 'Printing Your Cards',
                text: '<p>When ready, click Print to print the number cards you have selected.</p><p>Each card will have squares randomly assigned</p>',
                attachTo: {element: '#print-btn', on: 'left-start'},
            },
            {
                id: 'tour-done',
                title: 'Done!',
                text: '<p>That\'s it! You won\'t automatically see this tour next time. Click the Help button if you want to relaunch the tour.</p><p>Happy Bingo\'ing!</p>',
                attachTo: {element: '#tour-btn', on: 'left-start'},
                buttons: [
                    {text: 'Back', classes: 'btn btn-secondary', action: function() { return this.back(); }},
                    {text: 'Done', classes: 'btn btn-primary', action: function() { return this.next(); }},
                ]
            }
        ]);
    }

    start() {
        this.tour.start();
        try { 
            window.localStorage.setItem("tourshown", "true");
        } catch {}
    }

    startIfUnseen() {
        try {
            if(window.localStorage.getItem("tourshown") !== "true") {
                this.start();
            }
        } catch { }
    }

}

export default TourGuide;

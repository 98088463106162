<script>
    export let initialParams = {};

    import SaveIcon from "./icons/save.svelte";
    import LoadIcon from "./icons/load.svelte";
    import DeleteIcon from "./icons/delete.svelte";
    import InfoIcon from "./icons/info.svelte";
    import ImageIcon from "./icons/image.svelte";
    import TextIcon from "./icons/text.svelte";
    import PlusSquareIcon from "./icons/plus-square.svelte";
    import BingoCard from "./BingoCard.svelte";
    import Header from "./Header.svelte";
    import Footer from "./Footer.svelte";
    import ErrorModal from "./ErrorModal.svelte";
    import Calendar from "../js/Calendar.js";
    import CardModel from "../js/Card.js";
    import ImageUploader from "../js/ImageUploader.js";
    import TourGuide from "../js/TourGuide.js";
    import { CARD_TYPES, CARD_NAMES } from "../js/const/cardTypes.js";
    import { THEMES } from "../js/const/themes.js";
    import { onMount } from "svelte";

    let themes = Object.values(THEMES);
    let theme = initialParams?.theme || Calendar.getTheme(initialParams?.themeDate);
    let card = CardModel.createDefault(initialParams?.cardType);
    let numCardsPerPage = Math.max(1, initialParams.cardsPerPage || 1);
    let numCardsToPrint = 30;
    let doPrint = false;
    let gridSizeSelect;
    let imageUploader;
    let uploadImageForIndex;
    let cardNode;
    let resizeDebounceTimeout;
    let errorModal;
    let saveName = "card.bingo";
    let tourGuide;
    let includeDrawSheet = false;

    function onCardTypeChange(ev) {
        card.type = parseInt(ev.target.options[ev.target.selectedIndex].value, 10);
        if(card.type == CARD_TYPES.NUM_75) {
            card.gridSize = [5,5];
        }
    }

    function onGridSizeChange() {
        let val = gridSizeSelect.options[gridSizeSelect.selectedIndex].value;
        card.gridSize = val.split('x').map(x => parseInt(x, 10));
    }

    function onFreeSpaceChange(ev) {
        card.useFreeSpace = ev.target.checked;
    }

    function onCardThemeChange(ev) {
        theme = ev.target.options[ev.target.selectedIndex].value;
        loadTheme(theme);
    }

    function loadTheme(theme) { 
        if(theme === 'Basic') { return; }
        let themeSlug = theme.toLowerCase().replace(/[^\w]+/g, '-');
        let themeId = 'card-theme-sheet--' + themeSlug;
        if(!document.head.querySelector('#' + themeId)) {
            let stylesheet = document.createElement('link');
            stylesheet.rel = "stylesheet";
            stylesheet.type = 'text/css';
            stylesheet.href = `./css/card-themes/${themeSlug}.css`;
            stylesheet.id = themeId;
            document.head.appendChild(stylesheet);
        }
    }

    function onCustomItemChange(ev) {
        let index = parseInt(ev.target.getAttribute('data-item-index'), 10);
        card.customItems[index] = ev.target.value;
    }

    function onDeleteCustomItem(ev) {
        let index = parseInt(ev.target.closest('.delete-custom-item-btn').getAttribute('data-item-index'), 10);
        card.customItems.splice(index, 1);
        onGridSizeChange();
    }

    function onDeleteAllCustomItems() {
        card.customItems = [];
    }

    function onAddCustomItemClick() {
        card.customItems = [""].concat(card.customItems);
    }

    function onImageUploadClick(ev) {
        uploadImageForIndex = parseInt(ev.target.closest('.image-upload-btn').getAttribute('data-item-index'), 10);
        initImageUpload();
        imageUploader.open();
    }

    function initImageUpload() { 
        if(imageUploader) { return; }
        imageUploader = new ImageUploader();
        imageUploader.on("open", () => {
            let radio = document.querySelector("#uppload-service-radio-local");
            if(radio) { radio.checked = true; }
        });
        imageUploader.on("upload", (url) => {
            card.customItems[uploadImageForIndex] = {isImage: true, uri: url};
            card = card;
        });
        imageUploader.on('error', () => {
            showErrorMessage();
            imageUploader.close();
        });
    }

    function onImageRemoveClick(ev) {
        let index = parseInt(ev.target.closest('.image-remove-btn').getAttribute('data-item-index'), 10);
        card.customItems[index] = "";
    }

    function onPrintClick() {
        doPrint = true;
        requestAnimationFrame(() => {
            window.print();
            // window.print blocks, so once we get to this next line
            // that means the print dialog is gone, so we can reset our flag
            doPrint = false;
        });
    }

    function onPrintSizeChange(ev) {
        if(!ev.target.checked) { return; }
        numCardsPerPage = parseInt(ev.target.value, 10);
    }

    function onIncludeDrawSheetChange(ev) {
        includeDrawSheet = ev.target.checked;
    }

    function onSaveClick() {
        let saveNode = document.createElement("a");
        let saveFile = new Blob([JSON.stringify({theme, card, numCardsPerPage, numCardsToPrint, includeDrawSheet})], {type: "text/plain"});
        saveNode.href = URL.createObjectURL(saveFile);
        saveNode.download = saveName;
        saveNode.click();
    }

    function onLoadClick() {
        document.getElementById('load-file-input').click();
    }

    function onFileLoad(ev) { 
        let fileList = event.target.files;
        if(fileList.length == 0) { return; }
        let file = fileList[0];
        let reader = new FileReader();
        reader.addEventListener('load', (event) => {
            try {
                let json = JSON.parse(event.target.result);
                if(json.theme && themes.indexOf(json.theme) >= 0) { 
                    theme = json.theme; 
                    loadTheme(theme);
                }
                if(json.numCardsPerPage && [1,2,4].indexOf(json.numCardsPerPage) >= 0) {
                    numCardsPerPage = json.numCardsPerPage;
                }
                if(json.numCardsToPrint && parseInt(json.numCardsToPrint, 10) > 0) {
                    numCardsToPrint = json.numCardsToPrint;
                }
                if(json.card) { 
                    card = card.load(json.card);
                }
                if("includeDrawSheet" in json) {
                    includeDrawSheet = !!json.includeDrawSheet;
                }
                saveName = file.name;
            } catch(e) {
                console.log(e);
                showErrorMessage();
            }
        });
        reader.readAsText(file);
    }

    function initPopover(el) {
        new window.bootstrap.Popover(el);
    }

    function scaleCard() {
        cardNode.style.transform = 'scale(1, 1)';
        let {width: cardWidth, height: cardHeight} = cardNode.querySelector('.bingocard').getBoundingClientRect();
        let {width: parentWidth, height: parentHeight} = cardNode.parentNode.getBoundingClientRect();
        cardWidth += 20; // card margins
        if(numCardsPerPage == 2) {
            cardHeight *= 2;
        }
        else if(numCardsPerPage == 4) {
            cardWidth *= 2;
            cardHeight *= 2;
        }
        if(cardWidth > parentWidth) {
            let scale = Math.min(parentWidth / (cardWidth + 30), parentHeight / cardHeight);
            cardNode.style.transform = `scale(${scale}, ${scale})`;
        }
    }

    function initCardContainer(el) {
        let observer = new ResizeObserver((entries) => {
            if(resizeDebounceTimeout) {
                clearTimeout(resizeDebounceTimeout);
                resizeDebounceTimeout = null;
            }
            resizeDebounceTimeout = setTimeout(scaleCard, 25);
        });
        observer.observe(el);
    }

    function showErrorMessage(msg) {
        if(!errorModal) {
            errorModal = new bootstrap.Modal(document.getElementById('errorModal'), {});
        }
        errorModal.show();
    }

    function onShowTourClick() {
        tourGuide.start();
    }

    // initialize
    loadTheme(theme);
    onMount(() => {
        tourGuide = new TourGuide();
        tourGuide.startIfUnseen();
    });

</script>

<Header on:tourClick={onShowTourClick} />

<section id='main'>
    <div id='controls'>
        <h2 class='d-flex py-2'>
            <span>Customize</span>

            <button id='load-btn' type="button" class="btn btn-primary icon-link ms-auto me-2" on:click={onLoadClick}>
                <LoadIcon />
                Load
            </button>
            <button id='save-btn' type="button" class="btn btn-primary icon-link" on:click={onSaveClick}>
                <SaveIcon />
                Save
            </button>
        </h2>

        <div class="mb-3">
            <label for="card-title-control" class="form-label">Card Title</label>
            <input type="text" class="form-control" id="card-title-control" placeholder="Bingo Time!" bind:value={card.title}/>
        </div>

        <div id='card-theme-control-wrapper' class="mb-3">
            <label for="card-theme-control" class="form-label">Theme</label>
            <select name="card-theme-control" class="form-select" id="card-theme-control" size='1' on:change={onCardThemeChange}>
                {#each themes as themeName}
                    <option value='{themeName}' selected={theme == themeName}>{themeName}</option>
                {/each}
            </select>
        </div>

        <div id='card-type-control-wrapper' class="mb-3">
            <label for="card-type-control" class="form-label">Card Type</label>
            <select name="card-type-control" class="form-select" id="card-type-control" size='1' on:change={onCardTypeChange}>
                {#each Object.values(CARD_TYPES) as key}
                    <option value='{key}' selected={card.type == key}>{CARD_NAMES[key]}</option>
                {/each}
            </select>
        </div>

        {#if card.type == CARD_TYPES.CUSTOM}
            <div id='card-grid-size-control-wrapper' class='mb-3'>
                <label for="grid-size-control" class="form-label">Grid Size
                    <button 
                        type="button" 
                        class="help-link icon-link btn btn-link" 
                        data-bs-container="body" 
                        use:initPopover
                        data-bs-toggle="popover" 
                        data-bs-placement="top" 
                        data-bs-content="You can enter more items than there are squares on your card. If you do, then each card will be generated using a random subset of the items." 
                        data-bs-original-title="Grid Size">
                            <InfoIcon />
                            <span class='d-none'>Info</span>
                    </button>
                </label>
                <select bind:this={gridSizeSelect} name="grid-size-control" class="form-select" id="grid-size-control" size='1' on:change={onGridSizeChange}>
                    <option value='5x5' selected={card.gridSize[0] == 5 && card.gridSize[1] == 5}>5x5</option>
                    <option value='4x4' selected={card.gridSize[0] == 4 && card.gridSize[1] == 4}>4x4</option>
                    <option value='3x3' selected={card.gridSize[0] == 3 && card.gridSize[1] == 3}>3x3</option>
                </select>
            </div>

            <div id='card-custom-items-control-wrapper' class='mb-3'>
                <div class="accordion" id="customItemsAccordion">
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#customItemsCollapse" aria-expanded="true" aria-controls="customItemsCollapse">
                                Custom Content
                            </button>
                        </h2>
                        <div id="customItemsCollapse" class="accordion-collapse collapse show" data-bs-parent="#customItemsAccordion">
                            <div class="accordion-body">
                                <div class="clearfix">
                                    <button class='float-start btn me-auto icon-link add-custom-item-btn' on:click={onAddCustomItemClick}>
                                        <span class='fw-normal'>Add Item</span>
                                        <PlusSquareIcon />
                                    </button>
                                    <button class='float-end btn ms-auto icon-link delete-all-custom-item-btn' data-bs-toggle="modal" data-bs-target="#clearCustomContentModal">
                                        <span class='fw-normal'>Clear All</span>
                                        <DeleteIcon />
                                    </button>
                                </div>
                                {#each card.customItems as item, i}
                                    <div class="d-flex justify-content-between align-items-center mb-2">
                                        {#if (typeof item === 'object') && item.isImage}
                                            <button 
                                                class='btn icon-link image-remove-btn' 
                                                data-item-index={i} 
                                                on:click={onImageRemoveClick}>
                                                    <TextIcon />
                                                    <span class='d-none'>Remove Image</span>
                                            </button>
                                            <img class='custom-image-preview' src={item.uri} alt="Preview" />
                                        {:else}
                                            <button 
                                                class='btn icon-link image-upload-btn' 
                                                data-item-index={i} 
                                                on:click={onImageUploadClick}>
                                                    <ImageIcon />
                                                    <span class='d-none'>Upload Image</span>
                                            </button>
                                            <input 
                                                 type="text" 
                                                 class="form-control" 
                                                 id={"card-custom-item-control-" + i} 
                                                 data-item-index={i} 
                                                 placeholder="" 
                                                 value={item} 
                                                 on:keyup={onCustomItemChange}/>
                                        {/if}
                                        <button class='btn ms-auto icon-link delete-custom-item-btn' data-item-index={i} on:click={onDeleteCustomItem}>
                                            <DeleteIcon />
                                            <span class='d-none'>Delete</span>
                                        </button>
                                    </div>
                                {/each}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {:else if card.type == CARD_TYPES.NUM_75}
            <div id="card-column-headers-control-wrapper" class='mb-3'>
                <p>Column Headings</p>
                <div class='column-header-inputs'>
                    {#each card.columnHeaders as columnHeader, i}
                        {@const index = i + 1}
                        <div class="mb-3 column-header-input">
                            <label for={"card-column-header" + index + "-control"} class="form-label">Column #{index}</label>
                            <input type="text" class="form-control" id={"card-column-header" + index + "-control"} placeholder="B" bind:value={card.columnHeaders[i]}/>
                        </div>
                    {/each}
                </div>
            </div>
        {/if}

        <div id='card-freespace-control-wrapper' class="form-check mb-3">
            <input class="form-check-input" type="checkbox" value="1" id="card-freespace-control" checked={card.useFreeSpace} on:change={onFreeSpaceChange}/>
            <label class="form-check-label" for="card-freespace-control">
                Inlcude Free Space?
            </label>
            <button 
                type="button" 
                class="help-link icon-link btn btn-link" 
                data-bs-container="body" 
                use:initPopover
                data-bs-toggle="popover" 
                data-bs-placement="top" 
                data-bs-content="If selected a free space will be included in the center of the card. The bingo players mark this square off immediately. You can customize what text to display in this square" 
                data-bs-original-title="Include Free Space?" 
                aria-describedby="popoverFreeSpace">
                    <InfoIcon />
                    <span class='d-none'>Info</span>
            </button>
        </div>

        {#if card.useFreeSpace}
            <div class="mb-3">
                <label for="card-freespace-text-control" class="form-label">Freespace Text</label>
                <input type="text" class="form-control" id="card-freespace-text-control" placeholder="Freespace" bind:value={card.freeSpaceText}/>
            </div>
        {/if}

        <div id='font-size-control-wrapper' class='mb-3'>
            <label for="font-size-control" class="form-label">Font Size</label>
            <input type="range" class="form-range" min="0.5" max="2" step="0.1" id="font-size-control" bind:value={card.fontScale}/>
            <div class='d-flex form-text mt-0'>
                <span class=''>Smaller</span>
                <span class='ms-auto'>Larger</span>
            </div>
        </div>

    </div>

    <div id='preview' class="card">
        <h2 class="card-header d-flex">
            <span>
                Preview
                <button 
                    type="button" 
                    class="help-link icon-link btn btn-link" 
                    data-bs-container="body" 
                    use:initPopover
                    data-bs-toggle="popover" 
                    data-bs-placement="top" 
                    data-bs-content="The preview shows a sample card as it would be printed. Click the down icon on the right of the Print button to select the number of cards per page and number of cards to print. When printed each card will be randomly generated."
                    data-bs-original-title="Printing Cards">
                        <InfoIcon />
                        <span class='d-none'>Info</span>
                </button>
            </span>
            <div id="print-btn-group" class="btn-group ms-auto">
                <button id="print-btn" type="button" class="btn btn-primary icon-link ms-auto" on:click={onPrintClick}>
                    <img class='bi' src="./images/print.png" alt='Print'/>
                    Print
                </button>
                <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                    <span class="visually-hidden">Toggle Print Menu</span>
                </button>
                <ul class="dropdown-menu">
                    <li>
                        <div class='dropdown-item'>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="printSize" id="printSize1" value='1' checked={numCardsPerPage == 1} on:change={onPrintSizeChange}/>
                                <label class="form-check-label" for="printSize1">1 Card Per Page</label>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class='dropdown-item'>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="printSize" id="printSize2" value='2' checked={numCardsPerPage == 2} on:change={onPrintSizeChange}/>
                                <label class="form-check-label" for="printSize2">2 Cards Per Page</label>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class='dropdown-item'>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="printSize" id="printSize4" value='4' checked={numCardsPerPage == 4} on:change={onPrintSizeChange}/>
                                <label class="form-check-label" for="printSize4">4 Cards Per Page</label>
                            </div>
                        </div>
                    </li>
                    <li><hr class="dropdown-divider"></li>
                    <li>
                        <div class="dropdown-item">
                            <label for="card-print-num-control" class="form-label">Number Of Cards To Print</label>
                            <input type="number" min='1' class="form-control" id="card-print-num-control" bind:value={numCardsToPrint}/>
                        </div>
                    </li>
                    <li><hr class="dropdown-divider"></li>
                    <li>
                        <div class="dropdown-item">
                            <div class="form-check d-inline-block">
                                <input class="form-check-input" type="checkbox" name="includeDrawSheet" id="includeDrawSheet" value='1' checked={includeDrawSheet} on:change={onIncludeDrawSheetChange}/>
                                <label class="form-check-label" for="includeDrawSheet">Print Draw Sheet?</label>
                            </div>
                            <button 
                                type="button" 
                                class="help-link icon-link btn btn-link" 
                                data-bs-container="body" 
                                use:initPopover
                                data-bs-toggle="popover" 
                                data-bs-placement="top" 
                                data-bs-content="If checked, an additional sheet is printed that includes one of each possible item (your custom items if designing a custom card, or the numbers 1-75 if designing a 75-Ball card). You can cut these out to use for drawing the items to be called." 
                                data-bs-original-title="Include Draw Sheet?">
                                    <InfoIcon />
                                    <span class='d-none'>Info</span>
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </h2>
        <div use:initCardContainer class="card-body">
            <div bind:this={cardNode} class={'card-scaler cards-perpage--' + numCardsPerPage}>
                {#each Array(numCardsPerPage) as _, i (i)}
                    <BingoCard 
                        {theme} 
                        cardType={card.type}
                        title={card.title}
                        gridSize={card.gridSize}
                        fontScale={card.fontScale}
                        columnHeaders={card.columnHeaders}
                        finalCardItems={i > 0 ? card.finalCardItemsShuffled : card.finalCardItems} />
                {/each}
            </div>
        </div>
    </div>
</section>

<Footer />

<div class="modal fade" id="clearCustomContentModal" tabindex="-1" aria-labelledby="clearCustomContentModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="clearCustomContentModalLabel">Clear All Custom Content?</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Really clear all custom content?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-warning" data-bs-dismiss="modal" on:click={onDeleteAllCustomItems}>Clear All</button>
            </div>
        </div>
    </div>
</div>

<ErrorModal />

<input id="load-file-input" type="file" on:change={onFileLoad} accept=".bingo" />

<div class='d-none' id='sample-icons'>
    <PlusSquareIcon id="sample-plus-square-icon"/>
    <DeleteIcon id="sample-delete-icon"/>
    <TextIcon id="sample-text-icon" />
    <ImageIcon id="sample-image-icon" />
</div>

{#if doPrint}
    {#if includeDrawSheet}
        <div class='draw-sheet'>
            <BingoCard 
                theme={THEMES.BASIC}
                cardType={card.type}
                title=""
                gridSize={["auto","auto"]}
                fontScale={0.75}
                columnHeaders={[]}
                finalCardItems={card.fullCardItems} />
        </div>
    {/if}
    <div id="print" class={'cards-perpage--' + numCardsPerPage}>
        {#each Array(numCardsToPrint) as _}
            {@const nextCard = card.copy()}
            <BingoCard 
                {theme} 
                cardType={nextCard.type}
                title={nextCard.title}
                gridSize={nextCard.gridSize}
                fontScale={nextCard.fontScale}
                columnHeaders={card.columnHeaders}
                finalCardItems={nextCard.finalCardItemsShuffled} />
        {/each}
    </div>
{/if}

<style lang='scss'>
    #main { 
        display: flex;
        max-width: 1200px;
        min-width: 300px;
        margin: auto;
        gap: 2em;
    }
    #controls, #preview {
        width: 50%;
        box-sizing: border-box;
    }
    @media screen and (max-width: 800px) {
        #main {
            flex-direction: column;
            padding: 1em;
        }
        #controls, #preview {
            width: 100%;
        }
    }
    #preview { 
        border: 0;
    }

    .custom-image-preview {
        max-height: 45px;
        width: auto;
    }

    /* scaling the print-sized card preview into the space available */
    .card-body {
        overflow: hidden;
        padding: 0;
    }
    .card-scaler {
        width: fit-content;
        transform-origin: 0 0;
        padding: 15px;
        box-shadow: inset 0 0 10px #a2a8a263;
    }
    .cards-perpage--2 {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
    .cards-perpage--4 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }

    /* print the cards */
    #print, .drawsheet {
        display: none;
    }
    @page {
        size: A4;
        margin: 0;
        padding: 0;
        border: none;
        border-collapse: collapse;
    }
    @media print {
        :global(#header), #main, :global(#footer), .modal {
            display: none !important;
        }
        #print { 
            display: grid;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            border: 0;
        }
        .drawsheet,
        #print.cards-perpage--1 :global(.bingocard),
        #print.cards-perpage--2 :global(.bingocard:nth-child(even)),
        #print.cards-perpage--4 :global(.bingocard:nth-child(4n)) {
            page-break-after: always;
            page-break-before: never;
            break-after: always;
            break-before: never;
        }
        .drawsheet {
            display: block; 
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }
    }

    #footer {
        background: #eaeaea;
    }

    #load-file-input {
        pointer-events: none;
        visibility: hidden;
        position: absolute;
        bottom: 0;
    }

    .column-header-inputs {
        display: flex;
        justify-content: space-between;
    }
    .column-header-input {
        width: 3.5rem;
    }
    .column-header-input label {
        position: absolute;
        visibility: hidden;
        pointer-events: none;
    }
</style>

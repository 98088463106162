import TheirLocal from "uppload/dist/services/local.js";

class Local extends TheirLocal {

    constructor() {
        super(...arguments);
        let oldTemplate = this.template;
        this.template = function() {
            let output = oldTemplate.apply(this, arguments);
            return output.replace(/<button [^>]*uppload-button[^>]+>/g, "<button class='uppload-button uppload-button--cta btn btn-primary'>");
        };
    }

};

export default Local;

import { MicrolinkBaseClass } from "uppload/dist/helpers/microlink.js";

class URL extends MicrolinkBaseClass {

    constructor() {
        super(...arguments);
        this.name = "url";
        this.icon = `<svg aria-hidden="true" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="nonzero"><path d="M200 151l36-36a67 67 0 10-95-95L89 72a67 67 0 0012 105l28-28a31 31 0 01-14-51l52-52a31 31 0 0143 43l-15 15c6 15 8 31 5 47z"/><path d="M56 105l-36 36a67 67 0 1095 95l52-52a67 67 0 00-12-105l-28 28a31 31 0 0114 51l-52 52a31 31 0 01-43-43l15-15c-6-15-8-31-5-47z"/></g></svg>`;
        this.color = "#8e44ad";
        let oldTemplate = this.template;
        this.template = function() {
            let output = oldTemplate.apply(this, arguments);
            return output.replace(/<button[^>]+>/, '<button class="btn btn-primary" type="submit">');
        };
    }

};

export default URL;

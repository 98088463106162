import App from "../components/App.svelte";

let params = new URLSearchParams(window.location.search);

(new App({
    target: document.body,
    props: {
        initialParams: {
            theme: params.get('theme'),
            themeDate: params.has('date') ? new Date(params.get('date')) : undefined,
            cardType: params.has('cardType') ? parseInt(params.get('cardType'), 10) : undefined,
            cardsPerPage: parseInt(params.get('perpage'), 10) || undefined,
        }
    }
}));

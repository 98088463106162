<footer id="footer" class="wavy mt-5">
    <div class='container'>
        <div class="row">
            <div class="col-lg-12">
                <p>Made by <a target="_blank" href="https://www.instagram.com/joys.of.learning/">Teresa Marrone</a>.</p>
                <p>Find more fun stuff on <a target="_blank" href="https://www.teacherspayteachers.com/Store/Joys-Of-Learning-Teresa">my store</a>.</p>
            </div>
        </div>
    </div>
</footer>

<style lang='scss'>

a {
    color: #000;
}
.wavy {
    padding: 50px 1.5rem 0;
    /* mask created with https://css-generators.com/wavy-shapes/ */
    background: rgb(var(--bs-primary-rgb)) !important;
    --mask:
        radial-gradient(50.39px at 50% 68.75px,#000 99%,#0000 101%) calc(50% - 50px) 0/100px 51% repeat-x,
        radial-gradient(50.39px at 50% -43.75px,#0000 99%,#000 101%) 50% 25px/100px calc(101% - 25px) repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);
}
</style>

<script>
    import InfoIcon from "./icons/info.svelte";
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    function onTourClick() {
        dispatch('tourClick');
    }
</script>

<header id="header" class="mb-5">
    <nav class="navbar navbar-expand-lg bg-primary" data-bs-theme="dark">
        <div class="container">
            <h1 class="navbar-brand" href="#">
                <img src="./images/logo.png" alt="Joys Of Learning" />
                Bingo Card Creator
            </h1>
            <ul class="navbar-nav ms-md-auto">
                <li class="nav-item">
                    <button id='tour-btn' class="nav-link" on:click={onTourClick}>
                        <InfoIcon />
                        <span class="ms-2">Help</span>
                    </button>
                </li>
            </ul>
        </div>
    </nav>
</header>

<style lang='scss'>
    #header .navbar {
        --bs-navbar-brand-color: #43483e;
        --bs-navbar-brand-hover-color: #43483e;
        --bs-navbar-color: #43483e;
    }
    .navbar-brand {
        font-weight: bold;
    }
    .navbar-brand img {
        width: 80px;
        height: 40px;
        display: inline-block;
        margin-right: 1em;
    }
</style>

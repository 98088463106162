const THEMES = {
    BASIC: 'Basic', 
    FALL: 'Fall', 
    WINTER: 'Winter', 
    SPRING: 'Spring', 
    SUMMER: 'Summer', 
    VALENTINES: 'Valentines', 
    EASTER: 'Easter', 
    HALLOWEEN: 'Halloween', 
    CHRISTMAS: 'Christmas',
};
Object.freeze(THEMES);

export { THEMES };

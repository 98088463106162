const CARD_TYPES = {
    NUM_75: 1,
    CUSTOM: 0,
};
Object.freeze(CARD_TYPES);

const CARD_NAMES = {
    [CARD_TYPES.NUM_75]: "75-Ball (Numbers 1-75)",
    [CARD_TYPES.CUSTOM]: "Custom Content",
};
Object.freeze(CARD_NAMES);

export { CARD_TYPES, CARD_NAMES };

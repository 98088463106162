import { 
    Uppload, 
    en as imageLang, 
    //Local as UppLocal, 
    //URL as UppURL, 
    //Camera as UppCamera, 
    Crop as UppCrop, 
    //Preview as UppPreview,
    Blur as UppBlur,
    Brightness as UppBrightness,
    Contrast as UppContrast,
    Grayscale as UppGrayscale,
    HueRotate as UppHueRotate,
    //Invert as UppInvert,
    Saturate as UppSaturate,
    //Sepia as UppSepia
} from "uppload";
import UppLocal from "../vendor/uppload/local.js";
import UppURL from "../vendor/uppload/url.js";

class ImageUploader {

    constructor() {
        this.imageUploader = new Uppload({
            defaultService: "local",
            lang: imageLang,
            uploader: (fileBlob) => {
                return new Promise((resolve, reject) => {
                    var reader = new FileReader();
                    reader.onloadend = function() {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(fileBlob);
                });
            },
        });
        this.imageUploader.use([
            // services
            new UppLocal(), 
            new UppURL(), 
            //new UppCamera(),
            // effects
            new UppCrop(),
            new UppBlur(),
            new UppBrightness(),
            new UppContrast(),
            new UppGrayscale(),
            new UppHueRotate(),
            //new UppInvert(),
            new UppSaturate(),
            //new UppSepia(),
            //new UppPreview(),
        ]);
    }

    open() {
        this.imageUploader.open();
    }

    close() {
        this.imageUploader.close();
    }
    
    on(eventName, callback) {
        return this.imageUploader.on(eventName, callback);
    }
}

export default ImageUploader;

<script>
    export let cardType;
    export let theme;
    export let gridSize;
    export let title;
    export let finalCardItems;
    export let columnHeaders;
    export let fontScale;

    import { CARD_TYPES } from "../js/const/cardTypes.js";

    function displayCardImage(el, item) {
        el.src = item.uri;
        return {
            update(item) {
                el.src = item.uri;
            }
        };
    }
</script>

<div class={'bingocard theme--' + theme.toLowerCase()} style:--font-scale={fontScale}>
    {#if title}
        <h3 class='bingocard__title'><span class='bingocard__title-text'>{title}</span></h3>
    {/if}
    {#if cardType == CARD_TYPES.NUM_75 && columnHeaders?.length > 0}
        <div class='mb-0 bingocard__colheadings'>
            {#each columnHeaders as colHeader}
                <span class='bingocard__colheading'>{colHeader}</span>
            {/each}
        </div>
    {/if}
    <ul class={'list-unstyled mb-0 bingocard__items bingocard__items--' + gridSize.join('x')}>
        {#each finalCardItems as item, i (i)}
            <li class='bingocard__item'>
                {#if (typeof item === 'object') && item.isImage}
                    <img class='bingocard__val bingocard__val--image' use:displayCardImage={item} alt='Your Custom Item'/>
                {:else}
                    <span class='bingocard__val'>{item}</span>
                {/if}
            </li>
        {/each}
    </ul>
</div>

<style lang='scss'>
    .bingocard {
        --items-font-size: 26px;
        --items-font-color: #000;

        display: flex;
        flex-direction: column;
        width: calc(210mm - 20px);
        height: calc(297mm - 20px);
        margin: 10px 10px 0;
    }
    :global(.cards-perpage--2) .bingocard {
        height: calc((297mm - 20px) / 2);
    }
    :global(.cards-perpage--4) .bingocard {
        width: calc((210mm - 40px) / 2);
        height: calc((297mm - 40px) / 2);
    }

    .bingocard__title {
        text-align: center;
        font-size: 34px;
        margin-bottom: 0;
    }
    .bingocard__items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        flex-grow: 1;
        overflow: hidden;
        border: 1px solid var(--bingo-border-color, black);
    }
    .bingocard__items--3x3 { 
        grid-template-columns: 1fr 1fr 1fr; 
        grid-template-rows: 1fr 1fr 1fr; 
    }
    .bingocard__items--4x4 { 
        grid-template-columns: 1fr 1fr 1fr 1fr; 
        grid-template-rows: 1fr 1fr 1fr 1fr; 
    }
    .bingocard__items--autoxauto {
        grid-template-columns: repeat(10, 1fr);
        grid-auto-rows: minmax(60px, 1fr);
    }
    .bingocard__item {
        text-align: center;
        position: relative;
        border: 1px solid var(--bingo-border-color, black);
    }
    .bingocard__val {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        word-break: break-word;
        width: 100%;
        display: block;
        line-height: 1;
        font-size: calc(var(--items-font-size) * var(--font-scale, 1));
        color: var(--items-font-color, #000);
    }
    :global(.cards-perpage--4) .bingocard__val {
        --items-font-size: 20px;
    }
    .bingocard__val--image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .bingocard__colheadings {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        text-align: center;
        font-size: calc(var(--items-font-size) * .75);
        color: var(--items-font-color, #000);
    }
</style>

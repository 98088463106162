import { CARD_TYPES } from "./const/cardTypes.js";

class Card {

    constructor(cardType, gridSize, cardTitle, customItems, useFreeSpace, freeSpaceText, fontScale) {
        /**
         * @member {string}
         */
        this.type = cardType;
        /**
         * An (row, col) int tuple defining the number of rows and columns in the card grid
         * @member int[]
         */
        this.gridSize = gridSize;
        /**
         * The title of the card
         * @member {string}
         */
        this.title = cardTitle;
        /**
         * The custom space content, if card type is Custom
         * @member {string[]}
         */
        this.customItems = customItems;
        /**
         * The random number items for this card if card type is 1-75
         * @member {int[]}
         */
        this.numberItems = this.getRandomNumberItems();
        /**
         * The column header chars for number type cards
         * @member {string[]}
         */
        this.columnHeaders = ["B","I","N","G","O"];
        /**
         * Whether or not to include a free space
         * @member {bool}
         */
        this.useFreeSpace = useFreeSpace;
        /**
         * The text to display for the free space, if using free space
         * @member {string}
         */
        this.freeSpaceText = freeSpaceText;
        /**
         * Font size scale to adjust the base font size
         * @member {float}
         */
        this.fontScale = fontScale;
    }

    /**
     * @return {Card{
     */
    static createDefault(cardType) {
        cardType = cardType && Object.values(CARD_TYPES).indexOf(cardType) >= 0 ? cardType : CARD_TYPES.CUSTOM;
        return new Card(
            cardType,
            [5,5], 
            "Bingo Time!", 
            ["Let's", "Make", "Our", "Own", "Bingo", "Cards", "Baby!", "It's", "Quick", "And", "Painless", "And", "Lots", "Of", "Fun", "For", "The", "Whole", "Class", "Easy", "Peasy", "Lemon", "Squeezy", "Whoo", "Hoo!"],
            true,
            "Free Space",
            1.0
        );
    }

    load(jsonCard) {
        if(!jsonCard) { return; }
        if(jsonCard.type && Object.values(CARD_TYPES).indexOf(jsonCard.type) >= 0) {
            this.type = jsonCard.type;
        }
        if(jsonCard.gridSize && jsonCard.gridSize[0] == 5) { this.gridSize = [5,5]; }
        else if(jsonCard.gridSize && jsonCard.gridSize[0] == 4) { this.gridSize = [4,4]; }
        if(jsonCard.gridSize && jsonCard.gridSize[0] == 3) { this.gridSize = [3,3]; }
        this.title = jsonCard.title || this.title;
        this.customItems.length = 0;
        this.customItems = this.customItems.concat(jsonCard.customItems || []);
        this.useFreeSpace = !!jsonCard.useFreeSpace;
        this.freeSpaceText = jsonCard.freeSpaceText || "";
        if(jsonCard.fontScale >= 0.5 && jsonCard.fontScale <= 2.0) { 
            this.fontScale = jsonCard.fontScale;
        }
        return this;
    }

    /**
     * Creates and returns a copy of this card
     *
     * @return {Card}
     */
    copy() {
        return new Card(this.type, [].concat(this.gridSize), this.title, [].concat(this.customItems), this.useFreeSpace, this.freeSpaceText, this.fontScale);
    }

    /**
     * The number of spaces in this card grid
     * @member {int}
     */
    get length() {
        return this.gridSize[0] * this.gridSize[1];
    }

    /*
     * Generates a list of random number items to fill this card with
     *
     * @param {int}
     *
     * @return {int[]}
     */
    getRandomNumberItems() {
        let length = this.length;
        let alreadySelected = [];
        return Array(length).fill(0, 0, length).map((x, i) => {
            //"B" (numbers 1–15), 
            //"I" (numbers 16–30), 
            //"N" (numbers 31–45), 
            //"G" (numbers 46–60), 
            //"O" (numbers 61–75).
            let colIndex = i % this.gridSize[1];
            let min = colIndex * 15 + 1;
            let num;
            do {
                num = getRandomInt(min, min + 14);
            } while(alreadySelected[num]);
            alreadySelected[num] = true;
            return num;
        });
    }

    /**
     * The grid index to display the free space in
     * @member {int}
     */
    get freeSpaceIndex() {
        let colIndex = parseInt(Math.ceil(this.gridSize[1] / 2), 10) - 1;
        let rowIndex = parseInt(Math.ceil(this.gridSize[0] / 2), 10) - 1;
        return rowIndex * this.gridSize[1] + colIndex;
    }

    /**
     * Get a full set of all possible items
     *
     * @return {mixed[]}
     */
    get fullCardItems() {
        switch(this.type) {
            case CARD_TYPES.NUM_75:
                return Array(75).fill(0,0,75).map((x, i) => i + 1);
            case CARD_TYPES.CUSTOM:
                return this.customItems;
        }
        return [];
    }

    /**
     * The card space items for display
     * @member {mixed[]}
     */
    get finalCardItems() {
        return this.generateItems(false);
    }

    /**
     * The card space items for display (random set)
     * @member {mixed[]}
     */
    get finalCardItemsShuffled() {
        return this.generateItems(true);
    }

    generateItems(shuffleItems) { 
        let finalCardItems = [];
        let srcCardItems = [];
        switch(this.type) {
            case CARD_TYPES.NUM_75:
                srcCardItems = this.numberItems;
                shuffleItems = false; // we already are 'shuffled' as the array is randomized at generation time
                break;
            case CARD_TYPES.CUSTOM:
                srcCardItems = this.customItems;
                break;
        }
        finalCardItems = [].concat(srcCardItems);
        if(shuffleItems) {
            shuffle(finalCardItems);
        }
        let targetLength = this.length;
        if(finalCardItems.length < targetLength) {
            finalCardItems = finalCardItems.concat(Array(targetLength - finalCardItems.length).fill(""));
        }
        else if(finalCardItems.length > targetLength) {
            finalCardItems = finalCardItems.slice(0, targetLength);
        }
        if(this.useFreeSpace) {
            let freeSpaceIndex = this.freeSpaceIndex;
            finalCardItems[freeSpaceIndex] = this.freeSpaceText;
        }
        return finalCardItems;
    }

}

/**
 * Returns a random number between min and max inclusive
 * @param {int}
 * @param {int}
 * @return {int}
 */
function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function shuffle(array) {
    let currentIndex = array.length;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {

        // Pick a remaining element...
        let randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
}

export default Card;
